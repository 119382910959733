// import type { NextPage } from "next";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import Img from "@/components/Img";
import type { NextPageWithLayout } from "@/pages/_app";
import { useIntl } from "react-intl";
// import NoSSR from 'react-no-ssr';
import { Popover } from "antd"; //Tabs
import Seo from "@/components/Seo";
import Banner from "@/components/Banner";
import NodeMap from "@/components/NodeMap";
import Resource from "@/components/Resource";
import Bottom from "@/components/NewBottom";
import SwiperTabs from "@/components/SwiperTabs";
import {
  getContentClassify_api,
  getArticles_api,
  // getWebsiteBanners_api,
} from "@/api/operation";
import { getWebData } from "@/utils/webData";
import { nodeMapData } from "@/utils/nodeData";
import { getEnvironmentUrl } from "@/utils/utils";
import {
  defaultBanner,
  nodeData,
  advantageData,
  contentData,
  resourceList,
} from "@/models/home/data";
import "@/models/home/style.less";
import ContentCenterImg from "@/static/images/index-content-center.png";
import ball from "@/static/images/index-nodeintroc-ball.png";
import ballMobile from "@/static/images/index-nodeintroc-ball-mobile.png";
import advbg2 from "@/static/images/index-adv-bg2.png";
import advbg1 from "@/static/images/index-adv-bg1.jpg";
import resourcebg1 from "@/static/images/index-resource-bg.jpg";
import advbgMobile from "@/static/images/index-adv-bg1-mobile.jpg";
import contentMobile from "@/static/images/index-content-bg1-mobile.png";
const Home: NextPageWithLayout = (props: any) => {
  // console.log('Homeprops', props);
  const intl = useIntl();
  const router = useRouter();
  const locale = router.locale || "en-US";
  // const regionCode = "us-siliconvalley-1";
  const [articleData, setArticleData] = useState<{
    title?: string;
    introduction?: string;
  }>(props?.data?.article?.[0] || {});
  const [animateMap, setAnimateMap] = useState<any>({});
  // const [bannerData, setBannerData] = useState<any[]>(
  //   props?.data?.banner?.slice(0, 3) || defaultBanner,
  // );
  useEffect(() => {
    //手动增加类名启动动画
    const _fadeInUpBig: any = document.querySelectorAll(".add-fadeInUpBig");
    if (_fadeInUpBig?.length) {
      _fadeInUpBig.forEach((f: any) => {
        if (!f.classList.contains("fadeInUpBig")) {
          f.classList.add("wow", "fadeInUpBig");
        }
      });
    }
    const _bounceInRight: any = document.querySelectorAll(".add-bounceInRight");
    if (_bounceInRight?.length) {
      _bounceInRight.forEach((f: any) => {
        if (!f.classList.contains("bounceInRight")) {
          f.classList.add("wow", "bounceInRight");
        }
      });
    }

    window.addEventListener("scroll", handleScroll, true);
    return function cleanup() {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // setBannerData(props?.banner?.slice(0, 3) || defaultBanner);
    setArticleData(props?.data?.article?.[0] || {});
    getWebData({
      ...global.__webInfo,
      contentId: props?.data?.content?.[0]?.id,
    });
  }, [props]);
  const seo = {
    title: intl.formatMessage(
      { id: "pages.home.tdk.title" },
      { platformName: global.__webInfo?.platformName }
    ),
    icon: global.__webInfo?.deputyLogoUrl,
    keywords: intl.formatMessage(
      { id: "pages.home.tdk.keywords" },
      { platformName: global.__webInfo?.platformName }
    ),
    description: intl.formatMessage(
      { id: "pages.home.tdk.description" },
      { platformName: global.__webInfo?.platformName }
    ),
    metas: (
      <meta
        name="trustpilot-one-time-domain-verification-id"
        content="e63b0114-e74d-4e6c-b135-47595bdee83b"
      />
    ),
  };
  //滚动触发选中效果
  const handleScroll = () => {
    const top: number =
      document.body.scrollTop + document.documentElement.scrollTop;
    const node =
      document.getElementById("m-index-nodeIntroduction")?.offsetTop || 0;
    const advantages =
      document.getElementById("m-index-advantages")?.offsetTop || 0;
    const map = { ...animateMap };
    if (top > node + 50 && top < advantages - 80) {
      map.node = true;
    } else {
      map.node = false;
    }
    setAnimateMap(map);
  };
  // console.log("home", props);
  return (
    <>
      <Seo
        title={seo.title}
        icon={seo.icon}
        keywords={seo.keywords}
        description={seo.description}
        metas={seo.metas}
      />
      <h1 style={{ display: "none" }}>
        {intl.formatMessage(
          { id: "pages.home.h1" },
          { platformName: global.__webInfo?.platformName }
        )}
      </h1>
      <Banner data={defaultBanner} />

      <div className="m-index-nodeIntroduction" id="m-index-nodeIntroduction">
        {/* <NoSSR> */}
        <div
          className="animate add-fadeInUpBig"
          data-wow-duration="2s"
          data-wow-delay="0s"
          data-wow-offset="200"
        >
          <Img src={ball} alt={""} />
        </div>
        <div
          className="animateMobile add-fadeInUpBig"
          data-wow-duration="2s"
          data-wow-delay="0s"
          data-wow-offset="20"
        >
          <Img src={ballMobile} alt={""} />
        </div>
        {/* </NoSSR> */}
        <div className="m-boxModel">
          <h2 className="m-index-title">
            {intl.formatMessage({ id: "pages.home.NodeIntroduction.title" })}
          </h2>
          <div className="nodeIntroduction-list" id="NodeIntroduction">
            {nodeData?.map((v: any, index: number) => {
              const _class = index ? "" : "active";
              return v.show ? (
                <div key={index} className={`item-li ${_class}`}>
                  <div className="u-img">
                    <Img
                      src={v.img}
                      alt={intl.formatMessage(
                        { id: v.alt },
                        { platformName: global.__webInfo?.platformName }
                      )}
                    />
                  </div>
                  <h3 className="u-text">
                    <b>
                      {v.b}
                      {v.span ? <span>{v.span}</span> : null}
                    </b>
                    <p>{intl.formatMessage({ id: v.p })}</p>
                  </h3>
                </div>
              ) : null;
            })}
          </div>
        </div>
      </div>

      <div className="m-index-node" id="m-index-node">
        <div className="m-boxModel">
          <NodeMap
            data={nodeMapData}
            tab="LightNode"
            source="home"
            jump={animateMap?.node}
          />
          <div className="node-btn">
            <a
              className="u-btn advert-link"
              href={`/${locale}/speed/us-siliconvalley-1`}
              title="speed test"
              target="_blank"
            >
              {intl.formatMessage(
                { id: "pages.southeastaisa.node.btn" },
                { platformName: global.__webInfo?.platformName }
              )}
            </a>
            {/* </Link> */}
          </div>
        </div>
      </div>

      <div
        className={
          animateMap?.advantages
            ? "m-index-advantages animate"
            : "m-index-advantages"
        }
        id="m-index-advantages"
      >
        {/* <NoSSR> */}
        <div
          className="animate add-fadeInUpBig"
          data-wow-duration="2s"
          data-wow-delay="0s"
          data-wow-offset="200"
        >
          <Img src={advbg1} alt={""} />
        </div>
        <div
          className="animateBottom add-fadeInUpBig"
          data-wow-duration="2s"
          data-wow-delay="0s"
          data-wow-offset="200"
        >
          <Img src={advbg2} alt={""} />
        </div>
        <div
          className="animateMobile add-fadeInUpBig"
          data-wow-duration="2s"
          data-wow-delay="0s"
          data-wow-offset="100"
        >
          <Img src={advbgMobile} alt={""} />
        </div>
        {/* </NoSSR> */}
        <div className="m-boxModel">
          <h2 className="m-index-title">
            {intl.formatMessage(
              { id: "pages.home.advantages.title" },
              { platformName: global.__webInfo?.platformName }
            )}
          </h2>
          <SwiperTabs
            data={advantageData}
            type="card"
            className="advantages-list"
            vertical={true}
            layout="img"
            tabToH3={true}
          />
        </div>
      </div>

      <div
        className={
          animateMap?.resources
            ? "m-index-resources animate"
            : "m-index-resources"
        }
        id="m-index-resources"
      >
        {/* <NoSSR> */}
        <div
          className="animate add-fadeInUpBig"
          data-wow-duration="2s"
          data-wow-delay="0s"
          data-wow-offset="200"
        >
          <Img src={resourcebg1} alt={""} />
        </div>
        {/* </NoSSR> */}
        <div className="m-boxModel">
          <h2 className="m-index-title">
            <span>
              {intl.formatMessage(
                { id: "pages.advertNew.resource.title1" },
                { platformName: global.__webInfo?.platformName }
              )}
            </span>{" "}
            {intl.formatMessage(
              { id: "pages.advertNew.resource.title2" },
              { platformName: global.__webInfo?.platformName }
            )}
          </h2>
          <Resource btn={false} data={resourceList} />
        </div>
      </div>

      <Bottom
        content={
          <div className="m-index-content">
            <div
              className="animateMobile add-bounceInRight"
              data-wow-duration="2s"
              data-wow-delay="0s"
              data-wow-offset="100"
            >
              <Img src={contentMobile} alt={""} />
            </div>
            <div className="m-boxModel">
              <h2 className="m-index-title">
                {intl.formatMessage({ id: "pages.home.content.title" })}
              </h2>
              <div className="content-list">
                <div className="left">
                  <div className="img">
                    <Img
                      src={ContentCenterImg}
                      alt={intl.formatMessage(
                        { id: "pages.home.content.alt" },
                        { platformName: global.__webInfo?.platformName }
                      )}
                    />
                  </div>
                  <div className="img-text">
                    <p>
                      {intl.formatMessage({
                        id: "pages.home.content.the.news",
                      })}
                    </p>
                    <p>
                      {intl.formatMessage(
                        { id: "pages.home.content.about" },
                        { platformName: global.__webInfo?.platformName }
                      )}
                    </p>
                    <p>
                      {intl.formatMessage(
                        { id: "pages.home.content.LightNode" },
                        { platformName: global.__webInfo?.platformName }
                      )}
                    </p>
                  </div>
                  <div>
                    <Popover
                      content={articleData?.title}
                      overlayStyle={{ width: 500 }}
                    >
                      <strong>
                        <h4>{articleData?.title}</h4>
                      </strong>
                    </Popover>
                    <p>
                      {articleData?.introduction}
                      <Link
                        href={`/${locale}/content/${global.__webInfo?.contentId}`}
                      >
                        {intl.formatMessage({ id: "pages.home.content.view" })}
                      </Link>
                    </p>
                  </div>
                </div>
                <ul className="right">
                  {contentData?.map((v: any, i: number) => {
                    return (
                      <li
                        key={i}
                        onClick={() => {
                          if (!v.href) {
                            return;
                          }
                          if (v.key === "doc") {
                            window.open(v.href);
                          }
                          if (v.key === "new") {
                            router.push(
                              `/${locale}/${v.href}/${global.__webInfo?.contentId}`
                            );
                          }
                          if (v.key === "plan") {
                            router.push(`/${locale}/${v.href}`);
                          }
                        }}
                      >
                        <Img src={v.icon} alt={""} type="label" />
                        <p>
                          <h3>{intl.formatMessage({ id: v.title })}</h3>
                        </p>
                        <span>
                          {intl.formatMessage({
                            id: "pages.home.content.more",
                          })}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};
export async function getServerSideProps(context: any) {
  const { req } = context;
  const _origin = getEnvironmentUrl(req.headers);
  const data: any = { req: req?.headers };
  // console.log('context=', context);
  // console.log('reqreqreq=', _origin);
  try {
    console.time("homeapi");
    //经产品沟通，更新频率很低，国内访问较慢，所以屏蔽接口调用
    // console.time('bannerapi');
    // await getWebsiteBanners_api(url).then((res: any) => {
    //   console.timeEnd('bannerapi');
    //   data.banner = res.data?.map((v: any) => {
    //     return {
    //       ...v,
    //       alt: 'pages.home.banner.alt',
    //       mobileAlt: 'pages.home.banner.mobileAlt',
    //     };
    //   });
    // });
    /// console.time("classapi");
    await getContentClassify_api(_origin).then((res: any) => {
      ///console.timeEnd("classapi");
      data.content = res?.data || [];
    });
    if (data?.content?.length) {
      const params = {
        contentClassifyID: data.content[0]?.id,
        pageSize: 1,
      };
      console.time("Articles");
      await getArticles_api(params, _origin).then((res: any) => {
        console.timeEnd("Articles");
        data.article = res?.data || [];
      });
    }
    console.timeEnd("homeapi");
  } catch (e) {
    console.log("请求失败", e);
  }
  // console.log('props=', data);
  return { props: { data } };
}
export default Home;
