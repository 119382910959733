import banner1 from "@/static/images/pageBanner/index-banner.jpg";
import bannerMobile1 from "@/static/images/pageBanner/index-banner-mobile.jpg";
import node1 from "@/static/images/index-node-img1.png";
import node2 from "@/static/images/index-node-img2.png";
import node3 from "@/static/images/index-node-img3.png";
import node4 from "@/static/images/index-node-img4.png";
import node5 from "@/static/images/index-node-img5.png";
import node6 from "@/static/images/index-node-img6.png";
import Advantage1 from "@/static/images/index-advantage-terminal.png";
import Advantage2 from "@/static/images/index-advantage-resource.png";
import Advantage3 from "@/static/images/index-advantage-click.png";
import Advantage4 from "@/static/images/index-advantage-cost.png"; //index-advantage-terminal-icon.png
import AdvantageIcon1 from "@/static/images/index-advantage-terminal-icon.png";
import AdvantageIcon2 from "@/static/images/index-advantage-resource-icon.png";
import AdvantageIcon3 from "@/static/images/index-advantage-click-icon.png";
import AdvantageIcon4 from "@/static/images/index-advantage-cost-icon.png";
import content1 from "@/static/images/index-content-new.png";
import content2 from "@/static/images/index-content-doc.png";
// import content3 from "@/static/images/index-content-plan.png";
import resource1 from "@/static/images/advertNew-resource1.png";
import resource2 from "@/static/images/advertNew-resource2.png";
import resource3 from "@/static/images/advertNew-resource3.png";
// import banner2 from "@/static/images/pageBanner/index-banner-1.jpg";
// import bannerMobile2 from "@/static/images/pageBanner/index-banner-mobile-1.jpg";
export const defaultBanner = [
  // {
  //   url: banner2,
  //   alt: "pages.home.banner.alt",
  //   mobileUrl: bannerMobile2,
  //   mobileAlt: "pages.home.banner.mobileAlt",
  // },
  {
    url: banner1,
    alt: "pages.home.banner.alt",
    mobileUrl: bannerMobile1,
    mobileAlt: "pages.home.banner.mobileAlt",
    priority: true, //LCP最大内容绘制，优先级属性
  },
];
export const nodeData = [
  {
    img: node1,
    b: "40+",
    p: "pages.home.Node.desc1",
    show: true,
    alt: "pages.home.Node.alt",
  },
  {
    img: node2,
    b: "100+",
    p: "pages.home.Node.desc2",
    show: true,
    alt: "pages.home.Node.alt",
  },
  {
    img: node3,
    b: "6",
    p: "pages.home.Node.desc3",
    show: false,
    alt: "pages.home.Node.alt",
  },
  {
    img: node4,
    b: "20+",
    p: "pages.home.Node.desc4",
    show: false,
    alt: "pages.home.Node.alt",
  },
  {
    img: node5,
    b: "10",
    span: "Tbps",
    p: "pages.home.Node.desc5",
    show: true,
    alt: "pages.home.Node.alt",
  },
  {
    img: node6,
    b: "300+",
    p: "pages.home.Node.desc6",
    show: true,
    alt: "pages.home.Node.alt",
  },
];
export const advantageData = [
  {
    title: "pages.home.advantages.terminal.title",
    key: "terminal",
    icon: AdvantageIcon1,
    img: Advantage1,
    alt: "pages.home.advantage.terminal.alt",
  },
  {
    title: "pages.home.advantages.resources.title",
    key: "resources",
    icon: AdvantageIcon2,
    img: Advantage2,
    alt: "pages.home.advantage.resource.alt",
  },
  {
    title: "pages.home.advantages.click.title",
    key: "click",
    icon: AdvantageIcon3,
    img: Advantage3,
    alt: "pages.home.advantage.click.alt",
  },
  {
    title: "pages.home.advantages.cost.title",
    key: "cost",
    icon: AdvantageIcon4,
    img: Advantage4,
    alt: "pages.home.advantage.cost.alt",
  },
];
export const contentData = [
  {
    title: "pages.home.content.new",
    key: "new",
    icon: content1,
    href: "content",
  },
  {
    title: "pages.home.content.doc",
    key: "doc",
    icon: content2,
    href: "https://doc.lightnode.com/",
  },
  // {
  //   title: 'pages.home.content.plan',
  //   key: 'plan',
  //   icon: content3,
  //   href: 'cocreate',
  // },
];
export const resourceList = [
  {
    img: resource1,
    computer: "1 vCPU 2GB",
    storage: "50 GB",
    network: "2000 GB",
    price: "8.7",
  },
  {
    img: resource2,
    computer: "1 vCPU 2GB",
    storage: "50 GB",
    network: "2000 GB",
    price: "10.0",
  },
  {
    img: resource3,
    computer: "1 vCPU 2GB",
    storage: "50 GB",
    network: "2000 GB",
    price: "12.0",
  },
];
