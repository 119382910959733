import Link from "next/link";
import { useRouter } from "next/router";
import Img from "@/components/Img";
import { useIntl } from "react-intl";
import { useState, useEffect } from "react";
import { Form, Input, Button, notification, Menu } from "antd";
import type { MenuProps } from "antd";
import Fackbook from "@/static/images/icon-fb.svg";
import LINKEDIN from "@/static/images/icon-in.svg";
import ins from "@/static/images/icon-ins.svg";
import x from "@/static/images/icon-x.svg";
import threads from "@/static/images/icon-threads.svg";
import { putWebsiteSubscribe_api } from "@/api/useApi";
import { getLocation, filterCountryVPS, chinaAreaVPS } from "@/utils/nodeData";
import "./style.less";

const Footer = () => {
  const intl = useIntl();
  const router = useRouter();
  const locale = router.locale || "en-US";
  const [hostname, setHostname] = useState("");
  const [loading, setLoading] = useState(false);
  const [openLocation, setOpenLocation] = useState<string[]>([]);

  const [form] = Form.useForm();
  useEffect(() => {
    setHostname(window.location.hostname);
  }, []);
  const onFinish = (values: any) => {
    // console.log(values, global.__webInfo);
    if (global.__webInfo?.id) {
      setLoading(true);
      putWebsiteSubscribe_api({
        email: values.email,
        domainId: global.__webInfo?.id,
      }).then((res: any) => {
        setLoading(false);
        if (res?.success) {
          form?.resetFields();
          notification.success({
            message: intl.formatMessage({
              id: "components.footer.Connect.success",
            }),
          });
        } else if (
          res?.msg === "kpy_userweb_WebsiteSubscribeEmailAlreadyExists"
        ) {
          notification.error({
            message: intl.formatMessage({
              id: "components.footer.Connect.submit.errorOnly",
            }),
          });
        } else {
          notification.error({
            message: intl.formatMessage({
              id: "components.footer.Connect.submit.error",
            }),
          });
        }
      });
    }
  };
  //go页面zh-CN和zh-HK跳转zh
  const renderZhUrl = () => {
    return locale === "zh-CN" || locale === "zh-HK" ? "zh/" : "";
  };
  const onOpenLocationChange: MenuProps["onOpenChange"] = (keys) => {
    const latestOpenKey = keys?.find((key) => openLocation.indexOf(key) === -1);
    setOpenLocation(latestOpenKey ? [latestOpenKey] : []);
  };
  return (
    <footer id="m-footer">
      <div className="m-footer-top">
        <div className="m-boxModel">
          <dl className="flex-2">
            <dt>{intl.formatMessage({ id: "components.footer.Connect" })}</dt>
            <dd>
              {intl.formatMessage({ id: "components.footer.Connect.tips" })}
            </dd>
            <dd>
              {intl.formatMessage({ id: "components.footer.Connect.email" })}
            </dd>
            <dd>
              <Form
                form={form}
                onFinish={onFinish}
                layout="inline"
                className="m-footer-top-form"
              >
                <Form.Item
                  name="email"
                  label=""
                  colon={false}
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: intl.formatMessage({
                        id: "components.footer.Connect.error",
                      }),
                    },
                  ]}
                  normalize={(value: string) =>
                    value?.toLocaleLowerCase?.() || value
                  }
                >
                  <Input
                    placeholder={intl.formatMessage({
                      id: "components.footer.Connect.placeholder",
                    })}
                    style={{ width: 200 }}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    {intl.formatMessage({ id: "pages.advert.contact.btn" })}
                  </Button>
                </Form.Item>
              </Form>
            </dd>
          </dl>
          <dl className="flex-1-5">
            <dt>{intl.formatMessage({ id: "components.footer.Contact" })}</dt>
            <dd>{intl.formatMessage({ id: "components.footer.ADDRESS" })}</dd>
            <dd>{intl.formatMessage({ id: "components.footer.PHONE" })}</dd>
            <dd>{intl.formatMessage({ id: "components.footer.EMAIL" })}</dd>
            <dd>
              <a
                href="https://www.facebook.com/LightNodeVPS"
                target="_blank"
                rel="noreferrer nofollow"
                title="FACEBOOK"
              >
                <Img src={Fackbook} title="FACEBOOK" alt="FACEBOOK" />
              </a>
              <a
                href="https://x.com/LightNodeVPS"
                target="_blank"
                rel="noreferrer nofollow"
                title="X"
              >
                <Img src={x} title="X" alt="X" />
              </a>
              <a
                href="https://www.linkedin.com/company/lightnode/"
                target="_blank"
                rel="noreferrer nofollow"
                title="LINKEDIN"
              >
                <Img src={LINKEDIN} title="LINKEDIN" alt="LINKEDIN" />
              </a>
              <a
                href="https://www.instagram.com/lightnode2022/"
                target="_blank"
                rel="noreferrer nofollow"
                title="INS"
              >
                <Img src={ins} title="INS" alt="INS" />
              </a>
              <a
                href="https://www.threads.net/@lightnode2022"
                target="_blank"
                rel="noreferrer nofollow"
                title="THREADS"
              >
                <Img src={threads} title="THREADS" alt="THREADS" />
              </a>
            </dd>
          </dl>

          <dl>
            <dt>{intl.formatMessage({ id: "components.header.Location" })}</dt>
            <dd>
              <Menu
                mode="inline"
                className="m-footer-top-location"
                multiple={false}
                openKeys={openLocation}
                onOpenChange={onOpenLocationChange}
                forceSubMenuRender={true}
              >
                {getLocation()?.map((v: any, i: number) => {
                  return (
                    <Menu.SubMenu
                      key={i}
                      title={
                        <span>
                          {intl.formatMessage({
                            id: `components.header.${v.text}`,
                          })}
                        </span>
                      }
                    >
                      {v?.children?.map(
                        (m: {
                          text: string;
                          city: any;
                          country: any;
                          url: string;
                        }) => {
                          // 应xcr要求不展示重复国家
                          if (filterCountryVPS.includes(m.url)) {
                            return null;
                          }
                          // 应xcr要求展示国家，中国特殊展示，展示key修改
                          const _key = chinaAreaVPS.includes(m.url)
                            ? "city"
                            : "country";
                          return (
                            <Menu.Item key={m.text}>
                              <a
                                href={
                                  "https://go.lightnode.com/" +
                                  renderZhUrl() +
                                  m.url
                                }
                                target="_blank"
                                rel="noreferrer"
                                title={
                                  intl.formatMessage({
                                    id: `components.NodeMap.${_key}.${m.text}`,
                                  }) + " VPS"
                                }
                                //   `${
                                //   m?.[_key]?.[locale] || m?.[_key]?.["en-US"]
                                // } VPS`}
                              >
                                {/* {m?.[_key]?.[locale] || m?.[_key]?.["en-US"]}{" "} */}
                                {intl.formatMessage({
                                  id: `components.NodeMap.${_key}.${m.text}`,
                                }) + " VPS"}
                              </a>
                            </Menu.Item>
                          );
                        }
                      )}
                    </Menu.SubMenu>
                  );
                })}
              </Menu>
            </dd>
          </dl>
          <dl>
            <dt>
              <a
                href={"https://go.lightnode.com/"}
                target="_blank"
                rel="noreferrer"
                title={intl.formatMessage({
                  id: "components.footer.VPS Hosting",
                })}
              >
                {intl.formatMessage({ id: "components.footer.VPS Hosting" })}
              </a>
            </dt>
            <dd>
              <a
                href={`https://go.lightnode.com/${renderZhUrl()}buy-vps`}
                target="_blank"
                rel="noreferrer"
                title="Buy Vps"
              >
                Buy VPS
              </a>
            </dd>
            <dd>
              <a
                href={`https://go.lightnode.com/${renderZhUrl()}hourly-billing-vps`}
                target="_blank"
                rel="noreferrer"
                title="Hourly VPS"
              >
                Hourly VPS
              </a>
            </dd>
            <dd>
              <a
                href={`https://go.lightnode.com/${renderZhUrl()}paypal-vps`}
                target="_blank"
                rel="noreferrer"
                title="PayPal VPS"
              >
                PayPal VPS
              </a>
            </dd>
            <dd>
              <a
                href={`https://go.lightnode.com/${renderZhUrl()}cyberpanel-vps`}
                target="_blank"
                rel="noreferrer"
                title="CyberPanel VPS"
              >
                CyberPanel VPS
              </a>
            </dd>
            <dd>
              <a
                href={`https://go.lightnode.com/${renderZhUrl()}bluestacks-vps`}
                target="_blank"
                rel="noreferrer"
                title="BlueStacks VPS"
              >
                BlueStacks VPS
              </a>
            </dd>
            <dd>
              <a
                href={`https://go.lightnode.com/${renderZhUrl()}openlitespeed-wordpress-vps`}
                target="_blank"
                rel="noreferrer"
                title="Wordpress VPS"
              >
                Wordpress VPS
              </a>
            </dd>
            <dd>
              <a
                href={`https://go.lightnode.com/${renderZhUrl()}nvme-ssd-vps`}
                target="_blank"
                rel="noreferrer"
                title="NVMe SSD VPS"
              >
                NVMe SSD VPS
              </a>
            </dd>
            <dd>
              <a
                href={`https://go.lightnode.com/${renderZhUrl()}linux-vps`}
                target="_blank"
                rel="noreferrer"
                title="Linux VPS"
              >
                Linux VPS
              </a>
            </dd>
            <dd>
              <a
                href={`https://go.lightnode.com/${renderZhUrl()}mikrotik-vps`}
                target="_blank"
                rel="noreferrer"
                title="MikroTik VPS"
              >
                MikroTik VPS
              </a>
            </dd>
            <dd>
              <a
                href={`https://go.lightnode.com/${renderZhUrl()}windows-vps`}
                target="_blank"
                rel="noreferrer"
                title="Windows VPS"
              >
                Windows VPS
              </a>
            </dd>
            <dd>
              <a
                href={`https://go.lightnode.com/${renderZhUrl()}rdp-vps`}
                target="_blank"
                rel="noreferrer"
                title="RDP VPS"
              >
                RDP VPS
              </a>
            </dd>
          </dl>
          <dl>
            <dt>{intl.formatMessage({ id: "components.footer.Company" })}</dt>
            <dd>
              <Link
                href={`/${locale}/aboutUs`}
                title={intl.formatMessage({ id: "components.footer.Aboutus" })}
              >
                <a
                  title={intl.formatMessage({
                    id: "components.footer.Aboutus",
                  })}
                >
                  {intl.formatMessage({ id: "components.footer.Aboutus" })}
                </a>
              </Link>
            </dd>
            <dd>
              <a
                href={"https://doc.lightnode.com/"}
                target="_blank"
                rel="noreferrer"
                title={intl.formatMessage({ id: "components.footer.FAQ" })}
              >
                {intl.formatMessage({ id: "components.footer.FAQ" })}
              </a>
            </dd>
            <dt style={{ paddingTop: 20 }}>
              {intl.formatMessage({ id: "components.footer.Partner" })}
            </dt>
            <dd>
              <a
                href="https://www.lightcdn.com/"
                target="_blank"
                rel="noreferrer"
                title="LightCDN"
              >
                LightCDN
              </a>
            </dd>
            <dd>
              <a
                href="https://dnschecked.com"
                target="_blank"
                rel="noreferrer"
                title="DNS Propagation Checker"
              >
                DNS Propagation Checker
              </a>
            </dd>
          </dl>
          <dl>
            <dt>{intl.formatMessage({ id: "components.footer.legal" })}</dt>
            <dd>
              <Link
                href={`/${locale}/privacyPolicy`}
                title={intl.formatMessage({ id: "components.footer.Policy" })}
              >
                <a
                  title={intl.formatMessage({ id: "components.footer.Policy" })}
                >
                  {intl.formatMessage({ id: "components.footer.Policy" })}
                </a>
              </Link>
            </dd>
            {/* <dd>
              <a>{intl.formatMessage({ id: 'components.footer.SLA' })}</a>
            </dd> */}
            <dd>
              <a
                href="https://doc.lightnode.com/Acceptableusepolicy/Acceptableusepolicy.html"
                target="_blank"
                rel="noreferrer"
                title={intl.formatMessage({
                  id: "components.footer.Acceptable",
                })}
              >
                {intl.formatMessage({ id: "components.footer.Acceptable" })}
              </a>
            </dd>
            {/* <dd>
              <a>{intl.formatMessage({ id: 'components.footer.GDPR' })}</a>
            </dd> */}
            <dd>
              <Link
                href={`/${locale}/termsService`}
                title={intl.formatMessage({ id: "components.footer.Service" })}
              >
                <a
                  title={intl.formatMessage({
                    id: "components.footer.Service",
                  })}
                >
                  {intl.formatMessage({ id: "components.footer.Service" })}
                </a>
              </Link>
            </dd>
          </dl>
        </div>
      </div>
      <div className="m-footer-bottom">
        <div className="m-boxModel">
          {" "}
          ©{" "}
          <label id="footerCopyright">
            {new Date().getFullYear()} {hostname}
          </label>
          , LLC. All rights reserved.
        </div>
      </div>
    </footer>
  );
};
export default Footer;
