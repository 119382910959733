import { useIntl } from "react-intl";
import Img from "@/components/Img";
import "./style.less";
// img
import email from "@/static/images/email-white.png";
import google from "@/static/images/google-white.png";
import github from "@/static/images/github.png";
interface P {
  content?: any;
}
const Bottom = ({ content = null }: P) => {
  const intl = useIntl();
  return (
    <div className="m-index-bottom" id="m-index-bottom">
      {content}
      <div className="m-boxModel">
        <h2 className="m-index-title">
          {intl.formatMessage({ id: "pages.home.bottom.title" })}
        </h2>
        <div className="bottom-list">
          <label
            className="email"
            onClick={() => {
              window.open(global.__webInfo?.registerUrl);
            }}
          >
            <Img src={email} alt={""} />
            {intl.formatMessage({ id: "pages.home.bottom.email" })}
          </label>
          <label
            onClick={() => {
              window.open(global.__webInfo?.registerUrl);
            }}
          >
            <Img src={google} alt={""} />
            {intl.formatMessage({ id: "pages.home.bottom.google" })}
          </label>
          <label
            onClick={() => {
              window.open(global.__webInfo?.registerUrl);
            }}
          >
            <Img src={github} alt={""} />
            {intl.formatMessage({ id: "pages.home.bottom.github" })}
          </label>
        </div>
      </div>
    </div>
  );
};
export default Bottom;
