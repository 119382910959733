import "antd/dist/antd.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import "../styles/globals.css";
import "../styles/animate.min.css";
import "../styles/common.less";
import "@/contant/axios";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import { ReactElement, ReactNode, useEffect, useState } from "react";
import type { NextPage } from "next";
import React, { Component, ErrorInfo } from "react";
import { IntlProvider } from "react-intl";
import localeMessage from "../locale";
import Layout from "@/components/layout";
import { webData, getWebData } from "@/utils/webData";
import { getEnvironmentUrl } from "@/utils/utils";
import { setLocalStorage, getLocalStorage } from "@/utils/storage";
import { websiteinfo_api } from "@/api/useApi";
import { putPromoClick_api, postPromoVisit_api } from "@/api/promotion";
import moment from "moment";
global.__webInfo = webData;

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  webInfo?: any;
  query?: any;
  pathname?: any;
};
class ErrorBoundary extends Component {
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // 将错误发送到服务器端日志、 Sentry、 Bugsnag 等错误追踪工具
    console.error("Caught an error:", error, errorInfo);
    // 这里可以发起一个fetch请求或者使用第三方库（如Sentry）将错误上报
  }

  render() {
    const _props: any = this.props;
    const { children } = _props;
    return <>{children}</>;
  }
}

function MyApp(props: AppPropsWithLayout) {
  const { Component, pageProps, webInfo, query, pathname } = props;
  const router = useRouter();
  const { locale } = router;
  //@ts-ignore
  const selectLocale = localeMessage[locale];
  const getLayout = Component.getLayout || ((page: any) => page);
  const [appWebInfo, setAppWebInfo] = useState({});
  useEffect(() => {
    if (query?.inviteCode && query?.promoteWay) {
      const now: any = new Date().getTime();
      setLocalStorage(
        "inviteCode",
        query?.inviteCode,
        now + 1 * 60 * 60 * 1000
      );
      setLocalStorage(
        "promoteWay",
        query?.promoteWay,
        now + 1 * 60 * 60 * 1000
      );
    }
    if (query?.ref) {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1); // 设置为明天
      tomorrow.setHours(0, 0, 0, 0); // 设置时分秒为0，即明天的第一个小时
      const promoteId = query?.ref;
      setLocalStorage("promoteId", promoteId, tomorrow.getTime());
    }
    // PV: 只要刷新就记一次，UV: 通过缓存来记录用户唯一性，次日缓存失效, flag，判断UV是否增加
    if (getLocalStorage("promoteId")) {
      const promoteId = getLocalStorage("promoteId");
      const rendomName = promoteId + "ID";
      let flag = false;
      const time = localStorage.getItem(rendomName);
      if (!time || !moment().isSame(time, "day")) {
        window.localStorage.setItem(rendomName, moment().format("YYYY-MM-DD"));
        flag = true;
      }
      postPromoVisit_api({ promoteId, flag });
    }
    if (webInfo) {
      //客户端全局存储，区分服务端字段不相通
      getWebData({
        ...webInfo,
        locale,
        proto: window?.location?.protocol + "//",
        host: window?.location?.host,
        inviteCode: getLocalStorage("inviteCode"),
        promoteWay: getLocalStorage("promoteWay"),
        promoteId: getLocalStorage("promoteId"),
      });
      global.__webInfo = global.__webInfoServer;
      // setState时页面才会更新
      setAppWebInfo(webInfo);
    }
  }, [webInfo, pathname]);
  return (
    <ErrorBoundary>
      <IntlProvider
        messages={selectLocale}
        locale={locale || "en-US"}
        defaultLocale="en-US"
      >
        <Layout>{getLayout(<Component {...pageProps} />)}</Layout>
      </IntlProvider>
    </ErrorBoundary>
  );
}
MyApp.getInitialProps = async (context: any) => {
  const { ctx } = context;
  let webInfoServer: any = global.__webInfoServer || {};
  try {
    console.log(
      "MyApp-getInitialProps=",
      ctx.locale,
      ctx.req.method,
      ctx.pathname
    );
    if (!webInfoServer?.id) {
      global.__webInfoServer = webData;
    }
    // console.log("method-url=", ctx.req?.url?.substring(1)?.split('/'));
    //页面post请求，防止被攻击处理
    const _reqUrl = ctx.req?.url?.substring(1)?.split("/") || [];
    if (
      ctx.req?.method === "POST" &&
      _reqUrl.length < 3 &&
      !(_reqUrl[0] === "api" || _reqUrl[0] === "userApi")
    ) {
      // 如果是POST请求，重定向到405页面或返回405状态码
      ctx.res?.writeHead?.(405, { Location: "/405" });
      ctx.res?.end?.();
      return {};
      // 或者直接返回一个405状态码
      ctx.statusCode = 405;
      throw new Error("Method Not Allowed");
    }
    if (ctx.pathname !== "/404" && ctx.pathname !== "/_error") {
      // _origin不可提取到外面，因为访问页面第二次必会进入404，会导致headers为空
      const _origin = getEnvironmentUrl(ctx.req?.headers);
      if (
        ctx.query?.inviteCode &&
        ctx.query?.inviteCode !== webInfoServer?.inviteCode
      ) {
        /**
         * 推广页统计
         * !global.__webInfo.inviteCode（初次），ctx.query?.inviteCode（url链接）
         */
        console.time("推广统计prompClick接口");
        await putPromoClick_api(
          { promoCode: ctx.query?.inviteCode },
          _origin
        ).then(() => {
          console.timeEnd("推广统计prompClick接口");
        });
      }
      //---网站信息接口
      if (!webInfoServer?.id || webInfoServer?.id !== global.__webInfo?.id) {
        const resWebInfo = await websiteinfo_api(_origin);
        if (resWebInfo?.data) {
          webInfoServer = {
            ...webInfoServer,
            ...resWebInfo?.data,
          };
        }
      }
    }
  } catch (err) {
    console.log("MyApp-err", err);
  }
  return {
    ...context.pageProps,
    webInfo: webInfoServer,
    query: ctx.query,
    pathname: ctx.pathname,
  };
};
export default MyApp;
