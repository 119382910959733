export const setLocalStorage = (
  key: string,
  value: any,
  expirationTime: number
) => {
  const data = {
    value,
    expirationTime,
  };
  localStorage.setItem(key, JSON.stringify(data));
};
export const getLocalStorage = (key: string) => {
  const data = localStorage.getItem(key);
  if (data) {
    const _data = JSON.parse(data);
    const now = new Date().getTime();
    if (now < _data.expirationTime) {
      return _data.value;
    } else {
      localStorage.removeItem(key);
    }
  }
  return null;
};
